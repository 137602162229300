#root {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

div {
    box-sizing: border-box;
}

a {
    text-decoration: none;
}

.contain-route {
    width: 100%;

    &.true {
        height: 100vh;
        overflow: hidden;
        position: fixed;
    }
}

.content-container {
    display: flex;
    position: absolute;
    right: 0;
    width: calc(100% - 300px);
    justify-content: center;
}

.content-inner {
    display: flex;
    flex-direction: column;
    width: 80%;   
    margin-top: 40px;
    padding-bottom: 100px;
}

.column-header {
    display: flex;
    width: 100%;
    height: 50px;
    padding-left: 20px;
    margin-bottom: 40px;
    background: #cacaca;
    border-radius: 5px;
    @include Bebas-Neue();
    letter-spacing: 2px;
    align-items: center;
    font-size: 22px;
}

.action-column {
    display: flex;
    position: relative;
    width: 100%;
    padding: 15px 0;
}

.button {
    display: flex;
    flex-direction: row;
    @include OpenSans-Regular;
    font-size: 16px;
    align-items: center;
    cursor: pointer;
}

.icon-add {
    display: flex;
    width: 40px;
    height: 40px;
    padding-top: 5px;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    border: solid 3px #000;
    @include Bebas-Neue();
    font-size: 52px;
    color: #000;
}

.icon-back {
    display: flex;
    width: 20px;
    height: 20px;
    padding-top: 5px;
    justify-content: center;
    align-items: center;
    @include Bebas-Neue();
    font-size: 52px;
    color: #000;
}

.add-text {
    display: flex;
    margin-left: 10px;
    color: #000;
}

.btn-add-new {
    margin-left: 5px;
}

.btn {
    display: flex;
    @include Bebas-Neue();
    font-size: 22px;
    border: solid 3px #000;
    border-radius: 10px;
    padding: 10px 40px;
    letter-spacing: 3px;
    cursor: pointer;
    width: 100px;
    justify-content: center;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.section {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 25px 0;

    &.quarter {
        width: 25%;
    }

    &.half {
        width: 50%;
    }

    &.third {
        width: 33%;
    }

    &.three-quarters {
        width: 75%;
    }

    .label-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 10px;
    }

    .label {
        flex: 1;
        @include Bebas-Neue();
        font-size: 22px;
    }

    textarea {
        flex: 1;
        width: 100%;
        height: 100px;
        padding: 20px;
        @include OpenSans-Regular();
        font-size: 16px;
        box-sizing: border-box;
    }

    .textarea-large {
        height: 500px;

    }

    .input-full {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
    }

    .input-normal {
        display: flex;
        flex: 3;
        padding: 20px;
        @include OpenSans-Regular();
        font-size: 16px;
        box-sizing: border-box;
    }

    .action-row {
        display: flex;
        flex: 1;
        justify-content: flex-end;
    }

    .check-contain {
        display: flex;
        flex: 2;
        flex-direction: row;
        align-items: center;
        cursor: pointer;

        .label {
            margin-left: 10px;
        }

        .red {
            color: red;
        }
    }

    .checkbox {
        width: 20px;
        height: 20px;
        border: solid 5px #000;
    }

    .preview-box {
        position: relative;
        width: 100%;
        box-sizing: border-box;
        padding: 25px;
        border: solid 5px #000;
    }
}

.row {
    display: flex;
    padding: 10px 0;
    justify-content: center;
    align-items: center;
    border-bottom: solid 1px #000;
    @include OpenSans-Regular();
    font-size: 16px;

    &.odd {
        background-color: rgba(202,202,202,0.3);
    }

    .col {
        flex: 1;
        margin: 5px 10px;
        overflow: hidden;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .col-alt {
        display: flex;
        flex-direction: row;
    }
}

.header-row {
    @include Bebas-Neue();
    font-size: 22px;
}

.btn-save {
    margin-top: 50px;
    align-self: flex-start;
    color: red;
}

.btn-delete {
    color: red;
    @include Bebas-Neue();
    font-size: 16px;
    text-transform: uppercase;
}