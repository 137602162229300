@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Open+Sans&display=swap');

/*
@font-face {
    font-family: 'Gotham-Regular';
    src: url('../assets/fonts/Gotham-Book.eot');
    src: url('../assets/fonts/Gotham-Book.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Gotham-Book.woff2') format('woff2'),
        url('../assets/fonts/Gotham-Book.woff') format('woff'),
        url('../assets/fonts/Gotham-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
*/


@mixin font-smoothing() {
	text-rendering: optimizeLegibility !important;
	-webkit-font-smoothing: antialiased !important;
	font-smoothing: antialiased !important;
	-moz-osx-font-smoothing: grayscale !important;
}

@mixin Bebas-Neue() {
    font-family: 'Bebas Neue', cursive;
    font-weight: normal;
    font-style: normal;
    @include font-smoothing();
}

@mixin OpenSans-Regular() {
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    font-style: normal;
    @include font-smoothing();
}
