.add-campaign-container {

    .select-wrapper {
        position: relative;

    }

    select {
        width: 100%;
        padding: 20px;
        padding-right: 40px;
        @include OpenSans-Regular();
        font-size: 16px;
        box-sizing: border-box;
        appearance: none;
    }

    .arrow-down {
        position: absolute;
        right: 20px;
        top: calc(50% - 10px);
        width: 10px;
        height: 10px;
        border-right: solid 3px #000;
        border-bottom: solid 3px #000;
        transform: rotate(45deg);
    }

    .mr-25 {
        margin-right: 25px;
    }

    .preview {
        position: relative;
    }

    .btn-copy {
        width: auto;
        position: absolute;
        right: 0;
        top: -30px;
    }
}