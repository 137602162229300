.templates-container {
    box-sizing: border-box;

    .row {
        .col {
            flex: 2;

            &.small {
                flex: 1;
            }
        }
    }

}