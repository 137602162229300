.sidebar-container {
    position: absolute;
    display: flex;
    top: 150px;
    left: 0;
    height: calc(100vh - 150px);
    background: #fff;
    align-items: center;
    justify-content: center;
    transition: all 0.3s;
    width: 300px;
    z-index: 9;

    &.active {
        width: 100%;
        min-width: none;
    }

    .menu-container {
        li {
            @include Bebas-Neue();
            font-size: 42px;
            letter-spacing: 10px;
            margin-bottom: 25px;
            cursor: pointer;
            
            a {
                color: #000;
                text-decoration: none;
            }
        }
    }

    .btn-logout {
        position: absolute;
        bottom: 50px;
        right: 50px;
        @include OpenSans-Regular();
        cursor: pointer;
    }
}
