.questions-container {
    box-sizing: border-box;

    .row {
        .col {
            flex: 3;

            &.small {
                flex: 1;
            }
        }
    }
}